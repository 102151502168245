import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  SettingPositionOptions,
  TRowWidthOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SelectedGoToOption } from "../../contentPatterns.types";
import { EDITOR_INITIAL_VALUE } from "../BoxText/StyledText";
import {
  IBoxMultiMenuContent,
  IBoxMultiMenuContentSettings,
  IBoxMultiMenuDisplaySettings,
  IBoxMultiMenuSettings,
  MultiMenuItemType,
  MultiMenuStyleOptions,
  MultiMenuType,
  MultimenuHorizontalExpandOptions,
} from "./StyledMultiMenu.types";

export const MULTI_MENU_TEMPLATE = {
  shadow: "0px 4px 16px 0px rgba(196, 208, 216, 0.15)",
  menuBorder: "1px solid #eeeeee",
  groupBorderActive: {
    height: 1,
    color: "#000000",
  },
  groupPadding: "8px 12px",
};

export const BOX_MULTI_MENU_DISPLAY_PATTERN =
  (): IBoxMultiMenuDisplaySettings => ({
    background: {
      color: {
        id: "color$$1",
        custom: "#",
      },
    },
    border: {
      color: {
        id: "color$$1",
        custom: "#",
      },
      size: 0,
    },
    contentAlignment: {
      columns: AlignmentHorizontalOption.LEFT,
      group: AlignmentHorizontalOption.LEFT,
      openMenu: AlignmentHorizontalOption.CENTER,
    },
    font: {
      id: "typo$$1",
      custom: {
        family: FontFamilyOptions.ROBOTO,
        letterSpacing: 0,
        lineHeight: 0,
        size: 16,
        weight: FontWeightOptionsLongListOfWeights.Weight300,
      },
    },
    shadow: false,
    text: {
      id: "color$$1",
      custom: "#",
    },
    underline: {
      show: true,
      color: { id: "color$$1", custom: "#" },
      thickness: 5,
      gap: 10,
    },
    spacing: {
      itemHeader: { top: 0, right: 0, bottom: 0, left: 0 },
      itemHeaderGap: 0,
    },
    items: [
      {
        id: MultiMenuStyleOptions.STYLE_1,
        font: {
          id: "typo$$1",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            letterSpacing: 0,
            lineHeight: 0,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 16,
          },
        },
        link: {
          id: "link$$1",
          custom: {
            text: {
              activeColor: {
                id: "color$$1",
                custom: "#",
              },
              color: {
                id: "color$$1",
                custom: "#",
              },
              hoverColor: {
                id: "color$$1",
                custom: "#",
              },
            },
          },
        },
      },
      {
        id: MultiMenuStyleOptions.STYLE_2,
        font: {
          id: "typo$$2",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            letterSpacing: 0,
            lineHeight: 0,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 16,
          },
        },
        link: {
          id: "link$$2",
          custom: {
            text: {
              activeColor: {
                id: "color$$1",
                custom: "#",
              },
              color: {
                id: "color$$1",
                custom: "#",
              },
              hoverColor: {
                id: "color$$1",
                custom: "#",
              },
            },
          },
        },
      },
      {
        id: MultiMenuStyleOptions.STYLE_3,
        font: {
          id: "typo$$3",
          custom: {
            family: FontFamilyOptions.ROBOTO,
            letterSpacing: 0,
            lineHeight: 0,
            weight: FontWeightOptionsLongListOfWeights.Weight300,
            size: 16,
          },
        },
        link: {
          id: "link$$3",
          custom: {
            text: {
              activeColor: {
                id: "color$$1",
                custom: "#",
              },
              color: {
                id: "color$$1",
                custom: "#",
              },
              hoverColor: {
                id: "color$$1",
                custom: "#",
              },
            },
          },
        },
      },
    ],
  });

export const BOX_MULTI_MENU_CONTENT_SETTINGS_PATTERN = (
  menuType?: MultiMenuType
): IBoxMultiMenuContentSettings => ({
  type: menuType || MultiMenuType.MENU_HORIZONTAL,
  groups: [
    {
      width: {
        margin: {
          left: 20,
          right: 20,
        },
        percent: 100,
        selectedOption: TRowWidthOption.PERCENT,
      },
      linkType: {
        style: MultiMenuStyleOptions.STYLE_1,
        icon: {
          position: SettingPositionOptions.LEFT,
          size: 16,
          spacing: 5,
        },
      },
      editColumnsWidth: {
        expandType: MultimenuHorizontalExpandOptions.DROPDOWN,
        dropdownPercentage: 100,
        fullPercentage: 100,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      columns: [
        {
          items: [
            {
              linkType: {
                icon: {
                  position: SettingPositionOptions.LEFT,
                  size: 16,
                  spacing: 5,
                },
                style: MultiMenuStyleOptions.STYLE_1,
              },
              type: MultiMenuItemType.LINK,
            },
          ],
        },
      ],
    },
  ],
});

export const BOX_MULTI_MENU_CONTENT_PATTERN = (): IBoxMultiMenuContent => ({
  groups: [
    {
      heading: "Item",
      link: {
        showLinkOptions: false,
        selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
        goToRowSetting: {
          rowId: null,
        },
        goToPageSetting: {
          link: "",
          openInNewTab: false,
        },
      },
      linkType: { iconUrl: "" },
      columns: [
        {
          columnSpan: 12,
          items: [
            {
              heading: "Item",
              linkType: {
                iconUrl: "",
                link: {
                  showLinkOptions: false,
                  selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                  goToRowSetting: {
                    rowId: null,
                  },
                  goToPageSetting: {
                    link: "",
                    openInNewTab: false,
                  },
                },
              },
              imageType: {
                link: {
                  showLinkOptions: false,
                  selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                  goToRowSetting: {
                    rowId: null,
                  },
                  goToPageSetting: {
                    link: "",
                    openInNewTab: false,
                  },
                },
                seoDescription: "",
                url: "",
              },
              textType: JSON.stringify(EDITOR_INITIAL_VALUE),
            },
          ],
        },
      ],
    },
  ],
});

export const BOX_MULTI_MENU_PATTERN = () => ({
  ...BOX_MULTI_MENU_DISPLAY_PATTERN(),
  ...BOX_MULTI_MENU_CONTENT_SETTINGS_PATTERN(),
});

export const StyledMultiMenu = styled.div<IBoxMultiMenuSettings>``;
