import React from "react";
import { IIcon } from "../Icon.types";

const ArrowLeftIcon: React.FC<IIcon> = ({
  width = "24",
  height = "24",
  fill = "#000",
  viewBox = "0 0 24 24",
  onClick,
  className,
}: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 19l-7-7 7-7"
      ></path>
    </svg>
  );
};

export default ArrowLeftIcon;
