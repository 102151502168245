import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { IPopupProps } from "./Popup";

export const StyledPopup = styled.div<IPopupProps>(
  ({ desktopWidth, popupBackgroundColor, theme, mobileBottomPlacement }) => {
    return `

        position: fixed;
        left: 50%;
        ${mobileBottomPlacement ? "bottom: 0;" : "top: 50%;"}
        z-index: var(--z-index-popup);
        background:${popupBackgroundColor ? convertColorIdToHex(popupBackgroundColor, theme.colorPalette) : "white"};
        border-radius: 4px;
        transform: translate(${mobileBottomPlacement ? "-50%" : "-50%, -50%"});
        box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.37);
        width: ${desktopWidth ?? "100%"};

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 100%;
        }

        .popup__close-button {
          position: absolute;
          right: 0.75rem;
          top: 0.75rem;

          &:hover {
            opacity: 0.4;
          }
        }
    `;
  }
);

export const StyledPopupBackdrop = styled.div<{ backdropColor?: string }>(
  ({ backdropColor }) => {
    return `
  background-color: ${backdropColor ? backdropColor : "transparent"};
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: var(--zindex-header);
  width: 100%;
  height: 100%;
`;
  }
);
