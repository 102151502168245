import React, { Dispatch, SetStateAction, memo, useEffect } from "react";
import { useRouter } from "next/router";
import DynamicLinkWrapper from "../../../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import {
  IBoxMultiMenuContentSettings,
  IBoxMultiMenuDataSettings,
  IBoxMultiMenuDisplaySettings,
  TMenuGroupContent,
} from "../../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";

import { PortalPath } from "../../../../shared/portalPath/portalPath";
import { getCurrGroupSett } from "../../BoxMultiMenu.methods";
import {
  StyledMultiMenuGroupElement,
  StyledMultimenuIconContainer,
} from "./MultiMenuMobile.styled";
import MultiMenuMobileColumn from "./MultiMenuMobileColumn";
import ArrowRightIcon from "../../../../shared/icons/ArrowRightIcon/ArrowRightIcon";

interface IMultiMenuMobileGroup {
  group: TMenuGroupContent;
  setShowGroupIndex: Dispatch<SetStateAction<number>>;
  showGroupIndex: number;
  onClose: () => void;
  groupIndex: number;
  path: PortalPath;
  settings: IBoxMultiMenuContentSettings &
    IBoxMultiMenuDisplaySettings &
    IBoxMultiMenuDataSettings;
}

const MultiMenuMobileGroup: React.FC<IMultiMenuMobileGroup> = ({
  group,
  setShowGroupIndex,
  showGroupIndex,
  onClose,
  settings,
  groupIndex,
  path,
}) => {
  const router = useRouter();
  const columItemsExist: boolean =
    group.columns[0]?.items !== null && group.columns[0]?.items?.length >= 1;

  useEffect(() => {
    // Subscribe to the router's events
    router.events.on("routeChangeComplete", onClose);

    // Cleanup the subscription when the component is unmounted
    return () => {
      router.events.off("routeChangeComplete", onClose);
    };
  }, [router.events]);

  return (
    <>
      <StyledMultiMenuGroupElement
        onClick={() => columItemsExist && setShowGroupIndex(groupIndex)}
        hide={showGroupIndex !== -1}
      >
        <DynamicLinkWrapper
          linkStyle={
            settings.items?.find(
              (item) =>
                item.id ===
                getCurrGroupSett(groupIndex, settings)?.linkType?.style
            )?.link
          }
          fontStyle={
            settings.items?.find(
              (item) =>
                item.id ===
                getCurrGroupSett(groupIndex, settings)?.linkType?.style
            )?.font
          }
          icon={{
            iconUrl: group?.linkType?.iconUrl,
            ...settings?.groups[groupIndex]?.linkType.icon,
          }}
          link={group?.link}
        >
          <>
            <i className="icon"></i>
            {group.heading}
          </>
        </DynamicLinkWrapper>

        {columItemsExist && (
          <StyledMultimenuIconContainer $settings={settings}>
            <ArrowRightIcon />
          </StyledMultimenuIconContainer>
        )}
      </StyledMultiMenuGroupElement>

      {showGroupIndex === groupIndex && (
        <MultiMenuMobileColumn
          columns={group.columns}
          groupIndex={groupIndex}
          path={path}
          closeColumn={() => setShowGroupIndex(-1)}
          settings={settings}
          group={group}
        />
      )}
    </>
  );
};

export default memo(MultiMenuMobileGroup);
