import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxMultiMenuContentSettings,
  IBoxMultiMenuDisplaySettings,
} from "../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import Link from "next/link";
import { isAbsoluteUrl } from "@ecp-boxes/helpers/helpers";

export const hrefWrapper = (children: React.ReactNode, link: string | TUrl) => (
  <Link href={link} locale={isAbsoluteUrl(link) ? false : undefined}>
    {children}
  </Link>
);

export const getCurrItemSett = (
  indexes: number[],
  settings: IBoxMultiMenuContentSettings & IBoxMultiMenuDisplaySettings
) => {
  return settings?.groups?.[indexes[0]]?.columns?.[indexes[1]]?.items[
    indexes[2]
  ];
};

export const getCurrItemSettMultimenuLine = (
  indexes: number[],
  settings: IBoxMultiMenuContentSettings & IBoxMultiMenuDisplaySettings
) => {
  return settings?.groups?.[indexes[0]]?.columns?.[indexes[1]]?.items?.[
    indexes[2]
  ];
};

export const getCurrGroupSett = (
  groupIndex: number,
  settings: IBoxMultiMenuContentSettings & IBoxMultiMenuDisplaySettings
) => {
  return settings?.groups?.[groupIndex];
};
