import {
  IBoxMultiMenuContentSettings,
  IBoxMultiMenuDisplaySettings,
} from "../../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import { getCurrGroupSett } from "../../BoxMultiMenu.methods";

export const getCurrentStyle = (
  settings: IBoxMultiMenuContentSettings & IBoxMultiMenuDisplaySettings,
  groupIndex: number
) =>
  settings.items?.find(
    (item) =>
      item.id === getCurrGroupSett(groupIndex, settings)?.linkType?.style
  );
