import styled from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MULTI_MENU_TEMPLATE } from "../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu";
import composeCss, {
  alignmentOptionsToValue,
} from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { IMultimenuHorizontalSettings } from "./BoxMultiMenu.types";

export const StyledDropdownWrapperMultimenu = styled.div(
  ({
    settings,
    theme,
    groupIndex,
    isFullWidth,
    fullWidth,
  }: {
    settings: IMultimenuHorizontalSettings;
    theme: IThemeState;
    groupIndex: number;
    isFullWidth: boolean;
    fullWidth: number;
  }) => {
    return `
    ${
      isFullWidth === true
        ? `
        display: flex;
        flex-direction: row;
        justify-content: ${alignmentOptionsToValue(
          settings.contentAlignment.openMenu
        )};
        `
        : ""
    }
    width: ${fullWidth}px;
    .columns-wrapper{
      display: flex;
      width: 100%;
      padding:  ${
        settings.groups[groupIndex].padding
          ? composeCss.padding(settings.groups[groupIndex].padding)
          : ""
      };
    }

    .columns-container {
      border:  ${composeCss.border(
        { color: settings.border.color, width: settings.border.size },
        theme
      )} ;
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      width: ${
        isFullWidth
          ? settings.groups[groupIndex].editColumnsWidth?.fullPercentage
          : settings.groups[groupIndex].editColumnsWidth?.dropdownPercentage
      }%;
      overflow-x: scroll;
    }

    .columns-container > li {
      padding-inline-start:  ${
        settings.groups[groupIndex].padding
          ? `${settings.groups[groupIndex].padding.left}px`
          : ""
      };
      padding-inline-end:  ${
        settings.groups[groupIndex].padding
          ? `${settings.groups[groupIndex].padding.right}px`
          : ""
      };
    }

    .columns {
      background-color: ${convertColorIdToHex(
        settings.background.color,
        theme.colorPalette
      )};
      padding: 20px;

      padding-block-start:  ${
        settings.groups[groupIndex].padding
          ? `${settings.groups[groupIndex].padding.top}px`
          : ""
      };
      padding-block-end:  ${
        settings.groups[groupIndex].padding
          ? `${settings.groups[groupIndex].padding.bottom}px`
          : ""
      };
      ${settings.shadow ? `box-shadow: ${MULTI_MENU_TEMPLATE.shadow}` : ""};
  
      text-align: ${settings.contentAlignment.columns.toLowerCase()};
    }


  `;
  }
);
