import styled from "styled-components";
import {
  IBoxBaseSettings,
  IElementImageInline,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";

const StyledImageInline = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.renderAs ?? "img",
  show: props?.["$settings"].imageUrl !== "" && props?.["$settings"].show,
}))<IBoxBaseSettings<IElementImageInline>>(
  ({ $settings }) => `
  display: block;
  width: ${$settings?.width};
  opacity: ${
    typeof $settings?.opacity === "number" ? $settings.opacity / 100 : 1
  };
`
);

export default StyledImageInline;
