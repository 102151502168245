import ConditionalWrapper from "../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import DynamicLinkWrapper from "../../../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import { getImageNameFromUrl } from "../../../../helpers/helpers";
import serializeHtmlFromDescendant from "../../../../helpers/serializeHtmlFromDescendant";
import {
  MultiMenuItemType,
  TMenuGroupContent,
} from "../../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import StyledImageInline from "../../../../settingsPatterns/elements/StyledImageInline/StyledImageInline";
import StyledRichText from "../../../../settingsPatterns/elements/StyledRichText/StyledRichText";
import { getCurrItemSett, hrefWrapper } from "../../BoxMultiMenu.methods";
import { IMultimenuHorizontalSettings } from "../../BoxMultiMenu.types";

const MultiMenuHorizontaContent: React.FC<{
  group: TMenuGroupContent;
  groupIndex: number;
  settings: IMultimenuHorizontalSettings;
}> = ({ group, groupIndex, settings }) => {
  return (
    <ul className="columns-container multi-menu-container__horizontal__columns-container">
      {group.columns?.map((column, columnIndex) => (
        <li
          key={"column" + column.items.length + column.columnSpan}
          className="columns multi-menu-container__horizontal__columns-container__wrapper__columns"
          style={{
            gridColumn: `span ${column.columnSpan} / span ${column.columnSpan}`,
          }}
        >
          <ul className="items multi-menu-container__horizontal__columns-container__wrapper__single-column__items">
            {column.items.map((item, itemIndex) => (
              <li
                className={`item multi-menu-container__horizontal__columns-container__wrapper__single-column__items__single-item-${itemIndex}`}
                key={itemIndex}
              >
                {getCurrItemSett([groupIndex, columnIndex, itemIndex], settings)
                  ?.type === MultiMenuItemType.LINK && (
                  <DynamicLinkWrapper
                    linkStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrItemSett(
                            [groupIndex, columnIndex, itemIndex],
                            settings
                          )?.linkType?.style
                      )?.link
                    }
                    fontStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrItemSett(
                            [groupIndex, columnIndex, itemIndex],
                            settings
                          )?.linkType?.style
                      )?.font
                    }
                    icon={{
                      iconUrl: item.linkType?.iconUrl,
                      ...getCurrItemSett(
                        [groupIndex, columnIndex, itemIndex],
                        settings
                      )?.linkType?.icon,
                    }}
                    link={item.linkType?.link}
                  >
                    <>
                      <i className="icon"></i>
                      {item.heading}
                    </>
                  </DynamicLinkWrapper>
                )}
                {getCurrItemSett([groupIndex, columnIndex, itemIndex], settings)
                  ?.type === MultiMenuItemType.TEXT && (
                  <StyledRichText
                    $settings={{
                      ...settings,
                      text: {
                        color: settings.text,
                      },
                    }}
                  >
                    {serializeHtmlFromDescendant(JSON.parse(item.textType))}
                  </StyledRichText>
                )}
                {getCurrItemSett([groupIndex, columnIndex, itemIndex], settings)
                  ?.type === MultiMenuItemType.IMAGE && (
                  <ConditionalWrapper
                    wrapper={(children) =>
                      hrefWrapper(
                        children,
                        item.imageType.link.goToPageSetting?.link ?? ""
                      )
                    }
                    condition={
                      item.imageType.link.goToPageSetting?.link
                        ? item.imageType.link.goToPageSetting?.link.length > 0
                        : false
                    }
                  >
                    <StyledImageInline
                      src={item.imageType.url}
                      alt={
                        item.imageType.seoDescription
                          ? item.imageType.seoDescription
                          : getImageNameFromUrl(item.imageType.url)
                      }
                      $settings={{
                        imageUrl: item.imageType?.url,
                        ...item.imageType,
                      }}
                    />
                  </ConditionalWrapper>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default MultiMenuHorizontaContent;
