import { useState } from "react";
import ConditionalWrapper from "../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import DynamicLinkWrapper from "../../../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import { getImageNameFromUrl } from "../../../../helpers/helpers";
import serializeHtmlFromDescendant from "../../../../helpers/serializeHtmlFromDescendant";
import { MultiMenuItemType } from "../../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import StyledImageInline from "../../../../settingsPatterns/elements/StyledImageInline/StyledImageInline";
import StyledRichText from "../../../../settingsPatterns/elements/StyledRichText/StyledRichText";
import StyledMultiMenuList from "../../../../settingsPatterns/elements/multiMenu/StyledMultiMenuList";
import {
  getCurrItemSettMultimenuLine,
  hrefWrapper,
} from "../../BoxMultiMenu.methods";
import { IMultiMenuListProps } from "../../BoxMultiMenu.types";
import { getCurrentStyle } from "./MultiMenuList.methods";

const MultiMenuList: React.FC<IMultiMenuListProps> = ({
  content,
  settings,
  path,
}) => {
  const [activeGroup, setActiveGroup] = useState<number | null>(null);
  return (
    <StyledMultiMenuList
      $settings={settings}
      activeGroup={activeGroup}
      className="multi-menu-container__list"
    >
      <ul className="groups multi-menu-container__list-groups">
        {content.groups?.map((group, groupIndex) => (
          <li
            className={`group multi-menu-container__list__single-group-${groupIndex}`}
            key={groupIndex}
            onMouseEnter={() => setActiveGroup(groupIndex)}
          >
            <DynamicLinkWrapper
              path={path}
              linkStyle={getCurrentStyle(settings, groupIndex)?.link}
              fontStyle={getCurrentStyle(settings, groupIndex)?.font}
              icon={{
                iconUrl: group.linkType?.iconUrl,
                ...settings?.groups[groupIndex]?.linkType.icon,
              }}
              link={group?.link}
            >
              <i className="icon"></i>
              <div className="multi-menu-container__list__heading">
                {group.heading}
              </div>
            </DynamicLinkWrapper>
            {group.columns[0].items.length > 0 && (
              <ul
                className={`columns multi-menu-container__list__columns__group-${groupIndex}`}
              >
                {group.columns?.map((column, columnIndex) => {
                  return (
                    <li
                      className={`column multi-menu-container__list__single-column-${columnIndex}`}
                      key={columnIndex}
                    >
                      <ul
                        className={`items multi-menu-container__list__items__column-${columnIndex}`}
                      >
                        {column.items?.map((item, itemIndex) => {
                          return (
                            <li
                              className={`item multi-menu-container__list__single-item-${itemIndex}`}
                              key={itemIndex}
                            >
                              {getCurrItemSettMultimenuLine(
                                [groupIndex, columnIndex, itemIndex],
                                settings
                              )?.type === MultiMenuItemType.LINK && (
                                <DynamicLinkWrapper
                                  linkStyle={
                                    settings.items?.find(
                                      (item) =>
                                        item.id ===
                                        getCurrItemSettMultimenuLine(
                                          [groupIndex, columnIndex, itemIndex],
                                          settings
                                        )?.linkType?.style
                                    )?.link
                                  }
                                  fontStyle={
                                    settings.items?.find(
                                      (item) =>
                                        item.id ===
                                        getCurrItemSettMultimenuLine(
                                          [groupIndex, columnIndex, itemIndex],
                                          settings
                                        )?.linkType?.style
                                    )?.font
                                  }
                                  icon={{
                                    iconUrl: item.linkType?.iconUrl,
                                    ...getCurrItemSettMultimenuLine(
                                      [groupIndex, columnIndex, itemIndex],
                                      settings
                                    )?.linkType?.icon,
                                  }}
                                  link={item.linkType?.link}
                                >
                                  <>
                                    <i className="icon"></i>
                                    {item.heading}
                                  </>
                                </DynamicLinkWrapper>
                              )}
                              {getCurrItemSettMultimenuLine(
                                [groupIndex, columnIndex, itemIndex],
                                settings
                              )?.type === MultiMenuItemType.TEXT && (
                                <StyledRichText
                                  $settings={{
                                    ...settings,
                                    text: {
                                      color: settings.text,
                                    },
                                  }}
                                >
                                  {serializeHtmlFromDescendant(
                                    JSON.parse(item.textType)
                                  )}
                                </StyledRichText>
                              )}
                              {getCurrItemSettMultimenuLine(
                                [groupIndex, columnIndex, itemIndex],
                                settings
                              )?.type === MultiMenuItemType.IMAGE && (
                                <ConditionalWrapper
                                  wrapper={(children) =>
                                    hrefWrapper(
                                      children,
                                      item.imageType.link.goToPageSetting
                                        ?.link ?? ""
                                    )
                                  }
                                  condition={
                                    item.imageType.link?.goToPageSetting?.link
                                      ? item.imageType.link?.goToPageSetting
                                          ?.link.length > 0
                                      : false
                                  }
                                >
                                  <StyledImageInline
                                    src={item.imageType.url}
                                    alt={
                                      item.imageType.seoDescription
                                        ? item.imageType.seoDescription
                                        : getImageNameFromUrl(
                                            item.imageType.url
                                          )
                                    }
                                    $settings={{
                                      imageUrl: item.imageType?.url,
                                      ...item.imageType,
                                    }}
                                    className={`multi-menu-container__list__single-item-${itemIndex}__image`}
                                  />
                                </ConditionalWrapper>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </StyledMultiMenuList>
  );
};

export default MultiMenuList;
