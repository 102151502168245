import React from "react";
import { IIcon } from "../Icon.types";


const ArrowRightIcon: React.FC<IIcon> = ({
  width = "12",
  height = "12",
  fill = "#000",
  viewBox = "0 0 24 24",
  onClick,
  className,
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L7 7L0.999999 1"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
