import React from "react";
import { useTranslation } from "next-i18next";
import withHydrationOnDemand from "react-hydration-on-demand";
import { isPortalSide } from "../../helpers/helpers";
import { StyledMultiMenu } from "../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu";
import {
  MultiMenuDisplaySettingsScheme,
  MultiMenuType,
} from "../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { IBoxMultiMenuProps } from "./BoxMultiMenu.types";
import MultimenuHorizontal from "./MultimenuHorizontalDropdown/MultimenuHorizontal";
import MultiMenuList from "./views/MultiMenuList/MultiMenuList";
import MultiMenuMobile from "./views/MultiMenuMobile/MultiMenuMobile";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";

const BoxMultiMenu: React.FC<IBoxMultiMenuProps> = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePortal();

  const { contentSettings, content, settings } = props;
  const displaySettings = MultiMenuDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const conectedSettings = {
    ...displaySettings,
    ...contentSettings,
    ...settings,
  };

  if (content === undefined || content?.groups?.length === 0)
    return <div>{!isPortalSide() && t("box_multi_menu.empty_menu")}</div>;

  const showHamburgerMenu =
    props.settings.multimenu_mobile_hamburger_view?.toUpperCase() === "TRUE";

  const showMobileMultimenu = isMobile && showHamburgerMenu;

  return (
    <StyledMultiMenu
      $settings={settings}
      content={content}
      className="multi-menu-container multi-menu-custom-style"
    >
      {showMobileMultimenu && (
        <MultiMenuMobile
          content={content}
          settings={conectedSettings}
          data-testid="multimenu-mobile"
          path={props.path}
        />
      )}

      {conectedSettings.type === MultiMenuType.MENU_HORIZONTAL &&
        !showMobileMultimenu && (
          <MultimenuHorizontal
            content={content}
            settings={conectedSettings}
            data-testid="multimenu-horizontal"
            path={props.path}
            publicationRef={props.publicationRef}
          />
        )}

      {conectedSettings.type === MultiMenuType.MENU_LIST &&
        !showMobileMultimenu && (
          <MultiMenuList
            content={content}
            settings={conectedSettings}
            data-testid="multimenu-list"
            path={props.path}
          />
        )}
    </StyledMultiMenu>
  );
};

export default Memo(withHydrationOnDemand({ on: ["visible"] })(BoxMultiMenu));
