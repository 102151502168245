import { ReactNode, useContext } from "react";
import ReactDOM from "react-dom";
import { FrameContext } from "react-frame-component";

export interface PopupPortalProps {
  children: ReactNode;
}

const PopupPortal = ({ children }: PopupPortalProps) => {
  const { document: iframeDocument } = useContext(FrameContext);

  return typeof document !== "undefined" || iframeDocument !== undefined
    ? ReactDOM.createPortal(children, (iframeDocument ?? document).body)
    : null;
};

export default PopupPortal;
