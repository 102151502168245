import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const StyledMultimenuHorizontalDropdownContent = styled.div<{
  wrapperWidth: number | null;
  left: number | null;
  top: number | null;
  right: number | null;
  openHorizontalDirection: AlignmentHorizontalOption;
  fixedWidth?: boolean;
  menuWrapperPercentageWidth: number;
  dropdownWidth: number;
}>`
  position: absolute;
  z-index: var(--zindex-dropdown-content);

  ${({ wrapperWidth, openHorizontalDirection, fixedWidth }) =>
    openHorizontalDirection === AlignmentHorizontalOption.LEFT &&
    wrapperWidth &&
    !fixedWidth &&
    `width:${wrapperWidth}px`};

  ${({
    left,
    wrapperWidth,
    openHorizontalDirection,
    menuWrapperPercentageWidth,
    dropdownWidth,
  }) => {
    const percentageViewToNumber = menuWrapperPercentageWidth / 100;

    const dropdownIsWiderThanWindow =
      left &&
      dropdownWidth &&
      dropdownWidth * percentageViewToNumber + left > window.innerWidth;

    if (left === null || !wrapperWidth) return;

    const widthThatDidntFitOnScreen = () => {
      const widthThatDidntFitOnScreen =
        window.innerWidth - dropdownWidth * percentageViewToNumber + left;
      const newLeftPosition =
        widthThatDidntFitOnScreen < 0
          ? (widthThatDidntFitOnScreen + left) * -1
          : widthThatDidntFitOnScreen - left;
      return Math.max(newLeftPosition, 0);
    };

    return `left :${
      dropdownIsWiderThanWindow ? widthThatDidntFitOnScreen() : left
    }px`;
  }};
  ${({ top }) => top && `top:${top}px`};
  ${({ wrapperWidth, menuWrapperPercentageWidth, openHorizontalDirection }) =>
    wrapperWidth &&
    `width: calc(${wrapperWidth}px - ${menuWrapperPercentageWidth}%)`};
`;
