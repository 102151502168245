import styled, { css } from "styled-components";
import {
  AlignmentHorizontalOption,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../../global/global";
import { alignmentOptionsToValue } from "../../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import { IMultimenuHorizontalSettings } from "../../BoxMultiMenu.types";

export const StyledMultimenuContent = styled.div<{
  closeBackgroundColor?: TColorId;
  backgroundColor?: TColorId;
}>(({ closeBackgroundColor, backgroundColor, theme }) => {
  return css`
    position: fixed;
    z-index: var(--zindex-mobile-multimenu);
    background-color: ${backgroundColor
      ? convertColorIdToHex(backgroundColor, theme.colorPalette)
      : "#ffffff"};
    width: 100vw;
    height: 100dvh;
    display: flex;
    transform: translateY(-50%);
    .closeIconContainer {
      width: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${closeBackgroundColor
        ? convertColorIdToHex(closeBackgroundColor, theme.colorPalette)
        : "#81889c"};
    }
  `;
});

export const StyledMultimenuMobileItems = styled.div<{
  $settings: IMultimenuHorizontalSettings;
}>(({ $settings }) => {
  const paddings = {
    top: $settings?.multimenu_mobile_items_top_padding,
    left: $settings?.multimenu_mobile_items_left_padding,
  };

  return `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${paddings?.top} 0 0 ${paddings?.left};
  overflow: hidden;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
});

export const StyledMultiMenuGroupElement = styled.div<{ hide: boolean }>(
  ({ hide }) => {
    return `
        display: ${hide ? "none" : "flex"};
        justify-content: space-between;
        align-items: center;
      `;
  }
);

export const StyledMultimenuIconContainer = styled.div<{
  $settings: IMultimenuHorizontalSettings;
}>(({ $settings }) => {
  const paddings = {
    top: $settings?.multimenu_mobile_hamburger_top_padding,
    right: $settings?.multimenu_mobile_hamburger_right_padding,
    bottom: $settings?.multimenu_mobile_hamburger_bottom_padding,
    left: $settings?.multimenu_mobile_hamburger_left_padding,
  };

  return `
      display: flex;
      align-items: center;
      justify-content: center;
      margin: ${paddings?.top} ${paddings?.right} ${paddings?.bottom} ${paddings?.left};
    `;
});

export const StyledMultiMenuColumnHeader = styled.div<{
  backgroundColor?: TColorId;
}>(({ backgroundColor, theme }) => {
  return `
  background-color: ${
    backgroundColor
      ? convertColorIdToHex(backgroundColor, theme.colorPalette)
      : "#eeeeee"
  };
  display: flex;
  align-items: center;
  .leftIcon {
    margin-right: 5px;
  }
`;
});

export const StyledMultimenuMobileColumnItems = styled.div<{
  backgroundColor?: TColorId;
}>(({ backgroundColor, theme }) => {
  return `
  display: flex;
  height: 100%;
  justify-content: space-between;
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-y: scroll;
    overflow-x: hidden;
    }
  }
  .closeIconContainer {
    background-color: ${
      backgroundColor
        ? convertColorIdToHex(backgroundColor, theme.colorPalette)
        : "#81889c"
    };
  }
`;
});

export const StyledMultimenu = styled.div<{
  alignment: AlignmentHorizontalOption;
}>(({ alignment }) => {
  return `
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: ${alignmentOptionsToValue(alignment)};

      .multi-menu-mobile-container__popup {
        height: 100dvh;
      }
  
    `;
});
